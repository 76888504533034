const config = {
    STRIPE_KEY: "pk_test_51I9S1AFu900K5H6htE9tCqWhffpiGPgC21UyjJ3tzTX6kiIGecSv5TMWl5ZgylFNbuLjqss5wzzFJojhEcT7SW1o00xlULU4sF",
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
        REGION: "us-east-1",
        BUCKET: "notes-app-upload-dev-4436",
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://3rjcltjh8l.execute-api.us-east-1.amazonaws.com/dev",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_Bb7Xtr6Hp",
        APP_CLIENT_ID: "43cpfkvfjapbvt0ueadttc1qeg",
        IDENTITY_POOL_ID: "us-east-1:2bc2bd92-2e52-4e12-a355-3950a5024984",
    },
};

export default config;